import React, { useState, useCallback } from 'react';
import { apricot4, URL } from '@widgets/core';
import { ApricotInput, ApricotButton } from '../elements';
import localization from '../localization';
import $ from 'classnames';
import styles from './styles.module.scss';
import { reject, find } from 'lodash';
/**
 * Component creates hidden fields for signin/signout form
 * @param endpoint key of endpoint parameter
 */
var hiddenValues = function (endpointParams) {
    if (!endpointParams) {
        endpointParams = [];
    }
    return (React.createElement(React.Fragment, null, endpointParams.map(function (param) { return (React.createElement("input", { type: "hidden", name: param.key, value: param.value })); })));
};
/**
 * Helper funtion provides param array from object
 * @param endpointParams  Object
 */
var getEndpointParamArrayFromObject = function (endpointParams) {
    var params = [];
    for (var endpointKey in endpointParams) {
        params[endpointKey] = [];
        for (var paramKey in endpointParams[endpointKey]) {
            params[endpointKey].push({
                key: paramKey,
                value: endpointParams[endpointKey][paramKey],
            });
        }
    }
    return params;
};
/**
 * Call to action view used inside identity widget ex: Don't have an account? section
 * @param props
 */
export var CallToActionView = function (_a) {
    var href = _a.href, title = _a.title, label = _a.label, _b = _a.showIcon, showIcon = _b === void 0 ? true : _b, theme = _a.theme;
    var ux = theme || {};
    return (React.createElement("div", { className: $(styles.callToAction, ux.callToAction) },
        !showIcon && (React.createElement("span", { className: "visible-lg cb-glyph-" + (apricot4 ? 'content-' : '') + "multi cb-add-user", "aria-hidden": "true" })),
        React.createElement("h3", { className: $(!apricot4 && styles.robotoSlab, !apricot4 && styles.marginBottom24) }, title),
        React.createElement(ApricotButton, { href: href, tracking: "nav" }, label)));
};
/**
 * Login controls used in login form footer area. ex: Forgot username and password section
 * @param props
 */
export var LoginControlsView = function (_a) {
    var forgotUsernameUrl = _a.forgotUsernameUrl, forgotPasswordUrl = _a.forgotPasswordUrl;
    var username = (React.createElement("a", { href: forgotUsernameUrl, "data-cbtrack-linktype": "nav" },
        React.createElement("span", { className: "sr-only" }, "Forgot "),
        " ",
        localization.username));
    var password = (React.createElement("a", { href: forgotPasswordUrl, "data-cbtrack-linktype": "nav" },
        React.createElement("span", { className: "sr-only" }, "Forgot "),
        " ",
        localization.password));
    return (React.createElement("p", { className: $(!apricot4 && styles.noMarginBottom) },
        localization.forgot,
        " ",
        username,
        " or ",
        password,
        "?"));
};
/**
 * Actual Login form view
 * @param props
 */
export var LoginFormView = function (_a) {
    var authenticationUrl = _a.authenticationUrl, forgotUsernameUrl = _a.forgotUsernameUrl, forgotPasswordUrl = _a.forgotPasswordUrl, redirectUrl = _a.redirectUrl, endpointParams = _a.endpointParams, rememberMe = _a.rememberMe, appId = _a.appId, theme = _a.theme;
    var ux = theme || {};
    var params = getEndpointParamArrayFromObject(endpointParams);
    return (React.createElement("div", { className: $(ux.form) },
        React.createElement("div", { className: $(ux.header) },
            React.createElement("span", { className: "cb-glyph-" + (apricot4 ? 'content-' : '') + "multi cb-user hidden-xs-only hidden-sm-only", "aria-hidden": "true" }),
            React.createElement("h2", null, localization.signIn)),
        React.createElement("div", { className: $(ux.body) },
            React.createElement("form", { role: "form", action: authenticationUrl, method: "post" },
                React.createElement("input", { type: "hidden", name: "DURL", value: redirectUrl }),
                React.createElement("input", { type: "hidden", name: "appId", value: appId }),
                hiddenValues(params['authentication']),
                React.createElement(ApricotInput, { name: "username", label: localization.username, className: "cb-margin-bottom-24" }),
                React.createElement(ApricotInput, { name: "password", label: localization.password, type: "password" }),
                React.createElement(RememberMeInputView, { rememberMe: rememberMe }),
                React.createElement(ApricotButton, { tracking: "nav" }, apricot4 ? localization.login : localization.submit))),
        React.createElement("div", { className: $(ux.footer) },
            React.createElement(LoginControlsView, { forgotUsernameUrl: forgotUsernameUrl, forgotPasswordUrl: forgotPasswordUrl }))));
};
/**
 * Remomber me checkbox input
 * @param props
 */
var RememberMeInputView = function (_a) {
    var _b = _a.rememberMe, rememberMe = _b === void 0 ? '' : _b;
    var _c = useState(rememberMe !== ''), checked = _c[0], setChecked = _c[1];
    var ref = useCallback(function (node) {
        if (apricot4) {
            var CBForm = window.cb.apricot.CBForm;
            CBForm.customFormElement({ elem: node, type: 'checkbox' });
        }
    }, []);
    var input = (React.createElement("input", { ref: ref, type: "checkbox", id: "rememberMe", name: "rememberMe", className: "cb-checkbox", checked: checked, onChange: function () { return setChecked(!checked); }, value: checked ? 'true' : 'false' }));
    return React.createElement("div", { className: $(styles.rememberMe, !apricot4 && styles.noMarginBottom) }, apricot4 ? (React.createElement("div", { className: "cb-checkbox" },
        React.createElement("label", null,
            input,
            React.createElement("span", { className: "text" }, localization.rememberMe)))) : (React.createElement("div", { className: "checkbox" },
        React.createElement("label", { className: "checkbox", htmlFor: "rememberMe" },
            input,
            React.createElement("span", { className: "cb-span" }),
            localization.rememberMe))));
};
/**
 * Signout button component
 * @param props
 */
export var SignOutButtonView = function (_a) {
    var action = _a.action, redirectUrl = _a.redirectUrl, appId = _a.appId, className = _a.className, theme = _a.theme;
    var ux = theme || {};
    return (React.createElement("form", { acceptCharset: "utf-8", method: "post", action: action, className: $(ux['signOut'], className) },
        React.createElement("input", { type: "hidden", name: "userType", value: "S" }),
        React.createElement("input", { type: "hidden", name: "DURL", value: redirectUrl }),
        React.createElement("input", { type: "hidden", name: "appId", value: appId }),
        React.createElement("input", { type: "hidden", name: "formState", value: "1" }),
        React.createElement(ApricotButton, { href: redirectUrl, tracking: "nav", type: "link", onClick: function (e) {
                e.preventDefault();
                e.currentTarget.parentElement.submit();
            } }, localization.signOut)));
};
/**
 * Identity dashboard link component appears after user successfully logged in. Links shows based on type of account ie. student|professional
 * @param props
 */
export var IdentityLinksView = function (_a) {
    var allLinks = _a.allLinks, username = _a.username, userType = _a.userType, logoutUrl = _a.logoutUrl, redirectUrl = _a.redirectUrl, appId = _a.appId, theme = _a.theme, logoutRedirectUrl = _a.logoutRedirectUrl;
    var ux = theme || {};
    var links = reject(allLinks, { name: 'Account Settings' });
    var settingsLink = find(allLinks, { name: 'Account Settings' });
    var accountLink = {
        name: settingsLink.name,
        url: URL.withQueryParams(settingsLink.url, {
            apId: '' + appId,
            DURL: redirectUrl
        })
    };
    return (React.createElement("div", { className: $(styles.links, ux.links) },
        React.createElement("div", { className: $(styles.header, ux.header) },
            React.createElement("span", { className: "visible-lg cb-glyph-" + (apricot4 ? 'content-' : '') + "multi cb-add-user", "aria-hidden": "true" }),
            React.createElement("h2", null,
                localization.hello,
                ", ",
                username,
                "!")),
        React.createElement("div", { className: $(styles.body, ux.body) },
            React.createElement("div", { className: $("cb-item-list cb-item-list-simple") },
                React.createElement("ul", null, links.map(function (l) {
                    var _a;
                    return (React.createElement("li", { key: l.name },
                        React.createElement("a", { href: l.url, "data-cbtrack-linktype": "nav" },
                            React.createElement("div", { className: $("cb-item-text", (_a = {}, _a["" + styles.apricot4] = apricot4, _a)) },
                                React.createElement("p", { className: "cb-item-title" }, l.name)))));
                })))),
        React.createElement("div", { className: $(styles.footer, ux.footer) },
            React.createElement("a", { href: accountLink.url, "data-cbtrack-linktype": "nav" },
                React.createElement("span", { className: "cb-glyph cb-settings", "aria-hidden": "true" }),
                accountLink.name),
            React.createElement(SignOutButtonView, { action: logoutUrl, appId: appId, redirectUrl: logoutRedirectUrl ? logoutRedirectUrl : redirectUrl }))));
};
